import * as React from "react"


// markup
const NotFoundPage = () => {
  return (
    <main>
      <p> we couldn’t find what you were looking for.</p>
    </main>
  )
}

export default NotFoundPage
